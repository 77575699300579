<template>
  <div class="Waiting">
    <Tile z="1" class="lobbyInfo">
      <h2 class="font-color--primary">Game Lobby</h2>
      <h3 class="font-color--primary">Share this code with the other players</h3>
      <input type="text" :value="thisURL" id="lobbyCode"/>

      <QRCode :gameId="$store.state.game.game_obj.publicId"></QRCode>

      <div v-if="$store.state.game.game_obj.hostId === $store.state.player.publicId">
        <h3>Start when all players have joined</h3>

        <Button
          :callback="startGame">
          Start Game
        </Button>
      </div>

      <div v-if="$store.state.game.game_obj.hostId !== $store.state.player.publicId">
        <h3>The host will start the game soon</h3>
      </div>
    </Tile>
    
    <Player
      v-for="player in $store.state.game.game_obj.players" :key="player.publicId"
      name="player"
      :player="player"
      :game="$store.state.game.game_obj"
      :life="player.life"
      >
    </Player>
  </div>
</template>
<script>
    import store from '@/main.js';
    import Button from "@/components/Button.vue";
    import Tile from "@/components/Tile.vue";
    import QRCode from "@/components/QRCode.vue";
    import Player from "@/components/Player.vue";
    import router from '@/router/index.js';
    export default {
    name: 'Waiting',
    components: {
      Tile,
      QRCode,
      Player,
      Button
    },
    methods: {
      copyCode() {
        const copyText = document.querySelector("#lobbyCode");
        copyText.select();
        document.execCommand("copy");
      },
      startGame() {
        let hasConfirmed = Object.keys(this.$store.state.game.game_obj.players).length > 1;
        if(!hasConfirmed) {
          hasConfirmed = confirm("Are you sure you want to start this game with only one player?");
        }
        if(hasConfirmed) {
          this.$store.commit('start_game', this.$store.state.player.secretId);
          router.push(`/game/${this.$store.state.game.game_obj.publicId}/life`);
        }
          

      },
    },
    computed: {
      thisURL() {
        return `${process.env.VUE_APP_WEB_PATH}/game/${store.state.game.game_obj.publicId}/info`;
      },

      hasStarted() {
        return this.$store.state.game.game_obj.hasStarted;
      }
    },
    watch: {
      hasStarted: function(newV, oldV) {
        if(oldV === false && newV === true) {
          router.push(`/game/${this.$store.state.game.game_obj.publicId}/life`);
        }
      }
    }
  }
</script>
<style scoped lang="less">
.Waiting {
  width:100%;
}
.lobbyInfo {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .elipses {
    font-size: 1.5em;
    font-weight: bolder;
    color: white;
  }

  #lobbyCode {
    height: 2px;
    opacity: 0;
    position: absolute;
    top:0;
    left: 0;
    width: 2px;
  }  
}

</style>